var render = function render(){var _vm=this,_c=_vm._self._c;return _c('CCard',[_c('CCardHeader',{staticClass:"text-center bg-dark text-white"},[_c('b',[_vm._v(_vm._s(_vm.$t('label.VersionControlList')))])]),_c('CCardBody',[_c('CRow',[_c('CCol',{staticClass:"d-flex justify-content-end",attrs:{"sm":"12"}},[_c('div',[_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({placement:'top-end',content:_vm.$t('label.version')}),expression:"{placement:'top-end',content:$t('label.version')}"}],attrs:{"color":"add"},on:{"click":function($event){return _vm.toggleCreate()}}},[_c('CIcon',{attrs:{"name":"cil-playlist-add"}}),_vm._v("  "+_vm._s(_vm.$t('label.nueva'))+" ")],1)],1)])],1),_c('CRow',[_c('br'),_c('CCol',{attrs:{"col":"12","xl":"12"}},[_c('dataTableExtended',{staticClass:"align-center-row-datatable",attrs:{"hover":"","sorter":"","small":"","column-filter":"","table-filter":_vm.tableText.tableFilterText,"items-per-page-select":_vm.tableText.itemsPerPageText,"items":_vm.formatedItems,"fields":_vm.fields,"noItemsView":_vm.tableText.noItemsViewText,"items-per-page":5,"pagination":"","loading":_vm.loading},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_c('loading')]},proxy:true},{key:"Status",fn:function({ item }){return [_c('td',{staticClass:"cell-center text-center"},[_c('CBadge',{attrs:{"color":_vm.getBadge(item.Status)}},[_vm._v(" "+_vm._s(_vm.$t('label.'+item.Status))+" ")])],1)]}},{key:"options",fn:function({item}){return [_c('td',{staticClass:"center-cell"},[_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                                    content: _vm.$t('label.edit')+' '+_vm.$t('label.version'),
                                    placement: 'top-end'
                                }),expression:"{\n                                    content: $t('label.edit')+' '+$t('label.version'),\n                                    placement: 'top-end'\n                                }"}],staticClass:"mr-1",attrs:{"color":"edit","square":"","size":"sm"},on:{"click":function($event){return _vm.toggleEdit(item)}}},[_c('CIcon',{attrs:{"name":"pencil"}})],1),_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                                    content: _vm.$t('label.downloadApk'),
                                    placement: 'top-end'
                                }),expression:"{\n                                    content: $t('label.downloadApk'),\n                                    placement: 'top-end'\n                                }"}],staticClass:"mr-1",attrs:{"color":"add","size":"sm","square":"","href":item.FileRoute}},[_c('CIcon',{attrs:{"name":"cil-cloud-download"}})],1)],1)]}}])})],1)],1),_c('ModalAPK',{attrs:{"modal":_vm.modalActive,"title":_vm.titleModal,"isEdit":_vm.isEdit,"ApkItem":_vm.ApkItem},on:{"update:modal":function($event){_vm.modalActive=$event},"close":_vm.closeModal,"Update-list":_vm.Update}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }